// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { IEnvironment } from '@theia-cc/shared/helpers';

// Staging Environment
const PROJECT_ID = '3dnuszhs';
const DATASET = 'development';

if (!PROJECT_ID || !DATASET) {
  throw new Error(
    'Sanity project ID and dataset name are required. Go into `pv/src/environments` and set them.'
  );
}

export const environment: IEnvironment = {
  production: false,
  hmr: false,
  apiEndpoint: 'https://nbo-api-dev.offerten-rechner.ch',
  slackWebHook: '',
  sanity: {
    projectId: PROJECT_ID,
    dataset: DATASET,
    apiVersion: '2022-05-01',
    useCdn: false, // set to false for fresh data
  },
};
