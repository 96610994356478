import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-content',
  styles: [
    `
      .modal-footer {
        justify-content: space-between;
      }
    `,
  ],
  template: `
    <div class="modal-header">
      <div>{{ 'COMPONENT.MODAL_OFFERPREVIEW_AREA.TITLE' | translate }}</div>
    </div>
    <div class="modal-body offer-preview-selected-area__modal-body">
      <div id="plugin-viewer-shared-offerpreview-modal"></div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-block btn-primary"
        aria-label="Cancel"
        (click)="bsModalRef.hide()">
        {{ 'COMPONENT.MODAL_OFFERPREVIEW_AREA.BUTTON_ACCEPT' | translate }}
      </button>
    </div>
  `,
})
export class OfferPreviewSelectAreaComponentModalComponent {
  constructor(public bsModalRef: BsModalRef) {}
}
