import { NgRedux } from '@angular-redux/store';
import { DOCUMENT, Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { IAppStatePv, PvStateAction } from '@theia-cc/pv/state';
import { cleanEmptyProperties } from '@theia-cc/shared/helpers';
import { ShareLinkService, UserInfoService } from '@theia-cc/shared/services';
import { ConfigAction } from '@theia-cc/shared/store';

@Injectable()
export class ParamsToCollectedDataResolverPv implements Resolve<void> {
  constructor(
    private stepActions: PvStateAction,
    private userInfoService: UserInfoService,
    private shareLinkService: ShareLinkService,
    private store: NgRedux<IAppStatePv>,
    private location: Location,
    private configAction: ConfigAction,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    const { queryParams } = route;
    this.userInfoService.storeAdditionalAddresses(queryParams);
    const { collectedData, browserCallback } = this.shareLinkService.splitQueryParams(queryParams);
    this.configAction.storeBrowserCallback(browserCallback);
    this.stepActions.setConfigSettings(cleanEmptyProperties(collectedData, 'unknown'));

    if (Object.keys(cleanEmptyProperties(collectedData)).length || browserCallback) {
      this.stepActions.setConfigSettings(cleanEmptyProperties(collectedData, 'unknown'));

      setTimeout(() => {
        this.location.replaceState(
          this.shareLinkService.generateQueryParamsLink(
            this.shareLinkService.urlLinkQueryParamsSelector(this.store.getState()),
            this.document.location.pathname
          )
        );
      });
    }
  }
}
