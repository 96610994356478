import { NgRedux } from '@angular-redux/store';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { IAppStatePv, ICollectedDataPv, IHighDrainSystems } from '@theia-cc/pv/state';
import {
  caseAgnosticQueryParam,
  cleanEmptyProperties,
  getRestQueryParams,
  getUserAddressFromQueryParams,
  parseFormString,
} from '@theia-cc/shared/helpers';
import { IBuildingSummary, IUserAddressParams, IUserInfoParams } from '@theia-cc/shared/models';
import { ShareLinkService, USER_INFO_FIELDS } from '@theia-cc/shared/services';

export type ShareLinkQueryParams = Partial<ICollectedDataPv> &
  Partial<IBuildingSummary> &
  Partial<IUserAddressParams> &
  Partial<IUserInfoParams> &
  Partial<IHighDrainSystems> & { Language?: string; partnerId?: string };

@Injectable()
export class ShareLinkServicePv extends ShareLinkService {
  constructor(
    private store: NgRedux<IAppStatePv>,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    super();
  }

  urlLinkQueryParamsSelector(state: IAppStatePv): any {
    const {
      currentLanguage: Language,
      config: {
        queryParams: { partnerId },
      },
      collectedData: { nboLeadIdPv, variantIdPv },
    } = state;

    const { restQueryParams } = this.splitQueryParams(state.config.queryParams);

    const data = {
      partnerId,
      Language,
      nboLeadIdPv,
      variantIdPv,
      ...restQueryParams,
    };

    return cleanEmptyProperties(data);
  }

  shareLinkQueryParamsSelector(state: IAppStatePv): ShareLinkQueryParams {
    const {
      collectedData: {
        apartmentCount,
        averagePowerUsageKwh,
        buildingType,
        familyMemberCount,
        highDrainSystems: {
          HEATPUMP,
          ELECTRIC_HEATING,
          ELECTROBOILER,
          EMOBILITY_CHARGING,
          POOL_SAUNA,
        },
        highDrainSystemsKwh,
        isPvx,
        nboLeadIdPv,
        variantIdPv,
        roofArea,
        maxPvCoveredRoofArea,
        roofAreaChf,
        roofAreaKwh,
        roofAreaLifetimeChf,
        roofMaterial,
        roofShadow,
        roofTiltAngle,
        selectedRoofArea,
        totalPowerRequirementKwh,
        userAddress: { lat, lon, egid, place: City, street: Street, zip: ZipCode },
        user: {
          email: Email,
          firstName: FirstName,
          lastName: LastName,
          telephone: PhoneNumber,
          title: Title,
          companyName: CompanyName,
        },
        heatPump,
        battery,
        electroBoiler,
        chargingStationEmobility,
        heatPumpBoiler,
        nboSelectedTemplateIdPv,
        buildingData: {
          aboveSea,
          buildingRoofShape,
          buildingVolume,
          buildingGroundArea,
          buildingRoofArea,
          buildingEavesHeight,
          buildingRidgeHeight,
          buildingRoofOverhangArea,
          buildingWallArea,
          buildingLevels,
        },
        pvEarningsKwhM2,
        roofOrientation,
      },
      wizard: { version },
      currentLanguage: Language,
      config: {
        queryParams: { partnerId },
      },
    } = state;

    const { restQueryParams } = this.splitQueryParams(state.config.queryParams);

    const data = {
      apartmentCount,
      averagePowerUsageKwh,
      buildingType,
      familyMemberCount,

      HEATPUMP,
      ELECTRIC_HEATING,
      ELECTROBOILER,
      EMOBILITY_CHARGING,
      POOL_SAUNA,

      highDrainSystemsKwh,
      isPvx,
      nboLeadIdPv,
      variantIdPv,
      roofArea,
      maxPvCoveredRoofArea,
      roofAreaChf,
      roofAreaKwh,
      roofAreaLifetimeChf,
      roofMaterial,
      roofShadow,
      roofTiltAngle,
      selectedRoofArea,
      totalPowerRequirementKwh,

      lat,
      lon,
      egid,
      City,
      Street,
      ZipCode,

      Email,
      FirstName,
      LastName,
      PhoneNumber,
      Title,
      CompanyName,

      heatPump,
      battery,
      electroBoiler,
      chargingStationEmobility,
      heatPumpBoiler,
      nboSelectedTemplateIdPv,
      buildingRoofShape,
      buildingVolume,
      buildingGroundArea,
      buildingRoofArea,
      buildingEavesHeight,
      buildingRidgeHeight,
      buildingRoofOverhangArea,
      buildingWallArea,
      aboveSea,
      buildingLevels,
      pvEarningsKwhM2,
      roofOrientation,

      Language,
      partnerId,
      version,

      ...restQueryParams,
    };

    return cleanEmptyProperties(data);
  }

  splitQueryParams<
    T = {
      collectedData: Partial<ICollectedDataPv>;
      browserCallback: string;
      partnerId: string;
      Language: string;
      restQueryParams: Partial<ShareLinkQueryParams>;
    }
  >(queryParams: ShareLinkQueryParams): T {
    const getCaseAgnosticQueryParam = caseAgnosticQueryParam(queryParams as any);

    // tslint:disable:object-shorthand-properties-first
    const collectedData: Partial<ICollectedDataPv> = cleanEmptyProperties({
      apartmentCount: parseFormString.number(getCaseAgnosticQueryParam('apartmentCount')),
      averagePowerUsageKwh: parseFormString.number(
        getCaseAgnosticQueryParam('averagePowerUsageKwh')
      ),
      buildingType: getCaseAgnosticQueryParam('buildingType'),
      familyMemberCount: parseFormString.number(getCaseAgnosticQueryParam('familyMemberCount')),
      highDrainSystems: {
        HEATPUMP: parseFormString.boolean(getCaseAgnosticQueryParam('HEATPUMP')),
        ELECTRIC_HEATING: parseFormString.boolean(getCaseAgnosticQueryParam('ELECTRIC_HEATING')),
        ELECTROBOILER: parseFormString.boolean(getCaseAgnosticQueryParam('ELECTROBOILER')),
        EMOBILITY_CHARGING: parseFormString.boolean(
          getCaseAgnosticQueryParam('EMOBILITY_CHARGING')
        ),
        POOL_SAUNA: parseFormString.boolean(getCaseAgnosticQueryParam('POOL_SAUNA')),
      },

      highDrainSystemsKwh: parseFormString.number(getCaseAgnosticQueryParam('highDrainSystemsKwh')),
      isPvx: parseFormString.boolean(getCaseAgnosticQueryParam('isPvx')),
      nboLeadIdPv: getCaseAgnosticQueryParam('nboLeadIdPv'),
      variantIdPv: getCaseAgnosticQueryParam('variantIdPv'),
      roofArea: parseFormString.number(getCaseAgnosticQueryParam('roofArea')),
      maxPvCoveredRoofArea: parseFormString.number(
        getCaseAgnosticQueryParam('maxPvCoveredRoofArea')
      ),
      roofAreaChf: parseFormString.number(getCaseAgnosticQueryParam('roofAreaChf')),
      roofAreaKwh: parseFormString.number(getCaseAgnosticQueryParam('roofAreaKwh')),
      roofAreaLifetimeChf: parseFormString.number(getCaseAgnosticQueryParam('roofAreaLifetimeChf')),
      roofMaterial: getCaseAgnosticQueryParam('roofMaterial'),
      roofShadow: getCaseAgnosticQueryParam('roofShadow'),
      roofTiltAngle: parseFormString.number(getCaseAgnosticQueryParam('roofTiltAngle')),
      selectedRoofArea: parseFormString.number(getCaseAgnosticQueryParam('selectedRoofArea')),
      totalPowerRequirementKwh: parseFormString.number(
        getCaseAgnosticQueryParam('totalPowerRequirementKwh')
      ),

      userAddress: getUserAddressFromQueryParams(queryParams),
      user: {
        email: getCaseAgnosticQueryParam('Email'),
        firstName: getCaseAgnosticQueryParam('FirstName'),
        lastName: getCaseAgnosticQueryParam('LastName'),
        telephone: getCaseAgnosticQueryParam('PhoneNumber'),
        title: getCaseAgnosticQueryParam('Title'),
        companyName: getCaseAgnosticQueryParam('CompanyName'),
        emailDisclaimer: getCaseAgnosticQueryParam('nboLeadIdPv'),
      },

      heatPump: parseFormString.boolean(getCaseAgnosticQueryParam('heatPump')),
      battery: parseFormString.boolean(getCaseAgnosticQueryParam('battery')),
      electroBoiler: parseFormString.boolean(getCaseAgnosticQueryParam('electroBoiler')),
      chargingStationEmobility: parseFormString.boolean(
        getCaseAgnosticQueryParam('chargingStationEmobility')
      ),
      heatPumpBoiler: parseFormString.boolean(getCaseAgnosticQueryParam('heatPumpBoiler')),
      nboSelectedTemplateIdPv: getCaseAgnosticQueryParam('nboSelectedTemplateIdPv'),
      buildingData: {
        aboveSea: parseFormString.number(getCaseAgnosticQueryParam('aboveSea')),
        buildingVolume: parseFormString.number(getCaseAgnosticQueryParam('buildingVolume')),
        buildingGroundArea: parseFormString.number(getCaseAgnosticQueryParam('buildingGroundArea')),
        buildingRoofArea: parseFormString.number(getCaseAgnosticQueryParam('buildingRoofArea')),
        buildingEavesHeight: parseFormString.number(
          getCaseAgnosticQueryParam('buildingEavesHeight')
        ),
        buildingRidgeHeight: parseFormString.number(
          getCaseAgnosticQueryParam('buildingRidgeHeight')
        ),
        buildingRoofOverhangArea: parseFormString.number(
          getCaseAgnosticQueryParam('buildingRoofOverhangArea')
        ),
        buildingWallArea: parseFormString.number(getCaseAgnosticQueryParam('buildingWallArea')),
        buildingLevels: parseFormString.number(getCaseAgnosticQueryParam('buildingLevels')),
        buildingRoofShape: parseFormString.number(getCaseAgnosticQueryParam('buildingRoofShape')),
      },
      pvEarningsKwhM2: parseFormString.number(getCaseAgnosticQueryParam('pvEarningsKwhM2')),
      roofOrientation: parseFormString.number(getCaseAgnosticQueryParam('roofOrientation')),
      leadTag: getCaseAgnosticQueryParam('leadTag'),
    });
    // tslint:enable:object-shorthand-properties-first

    return {
      collectedData,
      browserCallback: getCaseAgnosticQueryParam('browserCallback'),
      partnerId: getCaseAgnosticQueryParam('partnerId'),
      Language: getCaseAgnosticQueryParam('Language'),
      restQueryParams: getRestQueryParams(queryParams as any),
    } as unknown as T;
  }
}
