import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactModalComponent } from './contact-modal/contact-modal.component';
import { SharedStepsUiModule } from '@theia-cc/shared/step-ui';
import { SharedFormUiModule } from '@theia-cc/shared/form-ui';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedUtilUiModule } from '@theia-cc/shared/util-ui';

@NgModule({
  imports: [
    CommonModule,
    SharedStepsUiModule,
    SharedUtilUiModule,
    SharedFormUiModule,
    TooltipModule,
  ],
  declarations: [ContactModalComponent],
  exports: [ContactModalComponent],
})
export class ContactModalModule {}
