import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedUtilUiModule } from '@theia-cc/shared/util-ui';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { InputNumberMaxLengthDirective } from './input/input-number-max-length.directive';
import { InputComponent } from './input/input.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { TextareaComponent } from './textarea/textarea.component';

@NgModule({
  declarations: [
    CheckboxComponent,
    InputComponent,
    RadioButtonComponent,
    TextareaComponent,
    InputNumberMaxLengthDirective,
  ],
  imports: [CommonModule, FormsModule, SharedUtilUiModule, TranslateModule],
  exports: [
    CheckboxComponent,
    InputComponent,
    RadioButtonComponent,
    TextareaComponent,
    InputNumberMaxLengthDirective,
    FormsModule,
    SharedUtilUiModule,
  ],
})
export class SharedFormUiModule {}
