<div class="form-group">
  <div class="textarea">
    <label for="{{ inputName }}">{{ inputLabel }}</label>
    <textarea
      [attr.id]="inputId"
      [disabled]="inputDisabled"
      class="textarea__field"
      [ngClass]="{
        'form-control': true,
        'textarea__field--disabled': inputDisabled,
        'textarea__field--error': validationError,
        'textarea__field--touched': inputTouched
      }"
      name="{{ inputName }}"
      (focus)="inputFocus()"
      (blur)="inputBlur(input.value)"
      (ngModelChange)="outputValue.emit($event)"
      [ngModel]="inputValue"
      (keydown.enter)="inputEnter.emit($event)"
      #input="ngModel"></textarea>
  </div>
</div>
