import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class CheckboxComponent {
  @Input() required: boolean;
  @Input() checked: boolean;
  @Input() disabled = false;
  @Input() id: any;
  @Output() checkboxChange = new EventEmitter<any>();
  @ViewChild('inputRef', { static: true }) inputRef: ElementRef<HTMLInputElement>;

  constructor(private translate: TranslateService) {}
  reportValidity() {
    const input = this.inputRef?.nativeElement;
    const validityState = input.validity;
    input?.setCustomValidity(
      validityState?.valueMissing ? this.translate.instant('CONTACT_FORM.ERROR.REQUIRED') : ''
    );
    input?.reportValidity();
  }
}
