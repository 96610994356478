<app-header></app-header>
<router-outlet></router-outlet>
<app-footer-floating (clickAction)="action.buttonActions($event)"></app-footer-floating>
<ngx-loading [show]="showLoadingSpinner$ | async" [config]="spinnerOptions"></ngx-loading>

<ng-template #offerPreviewLoadingSpinnerTemplate>
  <app-offer-preview-loading-spinner
    [image]="previewImageData$ | async"></app-offer-preview-loading-spinner>
</ng-template>

<ngx-loading
  [show]="showOfferPreviewLoadingSpinner$ | async"
  [config]="offerPreviewSpinnerOptions"
  [template]="offerPreviewLoadingSpinnerTemplate"></ngx-loading>

<div class="alert-container container">
  <template #alertContainer></template>
</div>
