<div
  [ngClass]="{
    'app-input': true,
    'app-input--touched': inputTouched,
    'app-input--required': isRequired,
    'app-input--disabled': inputDisabled,
    'app-input--readonly': readonly,
    'app-input--invalid': input.invalid && input.touched
  }">
  <div class="app-input__field-wrapper">
    <div class="form-group">
      <label *ngIf="!isLabelTranslatedViaCms" for="{{ inputName }}">{{ inputLabel }}</label>
      <label
        *ngIf="isLabelTranslatedViaCms && inputLabelCms"
        [appLocalizedText]="inputLabelCms"></label>
      <div class="app-input__input-wrapper">
        <input
          [ngClass]="{
            'form-control': true,
            'is-invalid': (validationError && input.touched) || (input.invalid && input.touched)
          }"
          type="{{ inputType }}"
          name="{{ inputName }}"
          id="{{ inputName }}"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          [attr.autofocus]="autofocus"
          [attr.pattern]="inputPattern"
          [required]="isRequired"
          autocomplete="{{ inputName }}"
          (focus)="inputFocus()"
          (blur)="inputBlur(input.value)"
          (ngModelChange)="inputValueChange.emit($event)"
          [(ngModel)]="inputValue"
          #input="ngModel"
          #inputRef
          [disabled]="inputDisabled"
          (input)="reportValidity()"
          (keydown)="inputKeyDown.emit($event)"
          (keydown.enter)="inputEnter.emit($event)"
          maxlength="{{ maxLength }}" />
        <div
          [ngClass]="{
            'app-input__indicator': true,
            'app-input__indicator--invalid':
              (validationError && input.touched) || (input.invalid && input.touched),
            'app-input__indicator--valid':
              (validationError && input.touched) || (input.invalid && input.touched)
          }">
          <app-icon
            *ngIf="
              (input.invalid || (validationError && input.touched)) &&
              (shouldShowValidation || input.dirty || input.touched)
            "
            name="close"
            size="32"></app-icon>
          <app-icon
            *ngIf="
              !(input.invalid || (validationError && input.touched)) &&
              (shouldShowValidation || input.dirty || input.touched)
            "
            name="check"
            size="32"></app-icon>
        </div>
      </div>
      <small id="{{ inputName }}" class="form-text text-muted" *ngIf="helpText"
        ><img
          style="display: inline-block; margin-right: 0.25rem; vertical-align: -0.1rem"
          src="assets/icons/var/icn-secure.svg"
          *ngIf="showLock" />{{ helpText }}</small
      >
    </div>
  </div>
</div>
