import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  // nice trick to make `@Input() name` required, also works for multiply fields
  selector: 'app-radio-button[name]',
  templateUrl: './radio-button.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class RadioButtonComponent implements OnInit {
  @Input() name: string;
  @Input() label: string;
  @Input() inline: boolean;
  @Input() selectedOption: string;
  @Input() options: any[];
  @Output()
  outputValue = new EventEmitter<any>();
  @ViewChild('radioForm', { static: true }) radioForm: ElementRef<HTMLFormElement>;
  @ViewChildren('inputRef') inputRef: QueryList<ElementRef<HTMLFormElement>>;
  @ViewChild('input') input: NgModel;
  @Input() public isRequired = false;
  @Input() public inputDisabled = false;

  public randomId = Math.ceil(Math.random() * 1000000);

  constructor(private translate: TranslateService) {}

  ngOnInit() {}

  updateModel(e: string) {
    this.outputValue.emit(e);
  }

  reportValidity() {
    const input = this.inputRef?.first?.nativeElement;
    const validityState = input.validity;
    input?.setCustomValidity(
      validityState?.valueMissing ? this.translate.instant('CONTACT_FORM.ERROR.REQUIRED') : ''
    );
    this.radioForm?.nativeElement.reportValidity();
  }
}
