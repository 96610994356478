import { DevToolsExtension, NgRedux, NgReduxModule } from '@angular-redux/store';
import { NgModule } from '@angular/core';
import { BackendService, CantonService, UserInfoService } from '@theia-cc/shared/services';
import {
  configReducer,
  deviceReducer,
  errorHandler,
  languageReducer,
  persistMiddleware,
  ReducerRegistry,
  wizardReducer,
} from '@theia-cc/shared/store';
import reduxThunk from 'redux-thunk';
import { INITIAL_STATE_CORE, StateCore } from './state';
import { ICollectedDataPv } from '@theia-cc/pv/state';

export const coreReducers = {
  config: configReducer,
  currentLanguage: languageReducer,
  wizard: wizardReducer,
  device: deviceReducer,
};

export const getItemId = (collectedData: Partial<ICollectedDataPv>) => {
  const { nboLeadIdPv, variantIdPv } = collectedData || {
    nboLeadIdPv: undefined,
    variantIdPv: undefined,
  };
  return nboLeadIdPv && variantIdPv ? `${nboLeadIdPv}-${variantIdPv}` : undefined;
};

@NgModule({
  imports: [NgReduxModule],
  providers: [BackendService, CantonService, UserInfoService, ReducerRegistry],
})
export class StoreModule {
  constructor(
    private store: NgRedux<StateCore>,
    private reducerRegistry: ReducerRegistry<StateCore>,
    devTools: DevToolsExtension
  ) {
    store.configureStore(
      state => state,
      INITIAL_STATE_CORE,
      [reduxThunk, errorHandler, persistMiddleware(getItemId)],
      devTools.isEnabled() ? [devTools.enhancer()] : []
    );
    reducerRegistry.registerReducers(coreReducers);
  }
}
