import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class TextareaComponent implements OnChanges {
  @Input() validationError: boolean;
  @Input() inputDisabled: boolean;
  @Input() inputLabel: string;
  @Input() inputName: string;
  @Input() inputType: string;
  @Input() inputValue: any;
  @Input() shouldShowValidation: boolean;
  @Input() inputId;
  @Output() outputValue = new EventEmitter<string>();
  @Output() inputEnter = new EventEmitter<string>();

  public inputTouched = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputValue) {
      this.inputTouched =
        changes.inputValue.currentValue !== '' && changes.inputValue.currentValue !== undefined;
    }
  }

  inputFocus() {
    this.inputTouched = true;
  }

  inputBlur(value) {
    if (!value || value === '') {
      this.inputTouched = false;
    }
  }
}
