import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BASE_PATH } from '@api-cc';
import { TranslateModule, TranslateParser } from '@ngx-translate/core';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { IPartnerConfigPv, PARTNER_CONFIG_PV } from '@theia-cc/pv/core';
import { PvStateAction, PvStateEffect } from '@theia-cc/pv/state';
import {
  CONSTANTS,
  ENVIRONMENT,
  ILocaleConfig,
  LOCALE_CONFIG,
  WIZARD_CONFIG,
} from '@theia-cc/shared/helpers';
import { SharedRootStepUiModule } from '@theia-cc/shared/root-step-ui';
import {
  EntryStepGuard,
  getAppInitConfig,
  HttpErrorInterceptor,
  initAppWizardSettings,
  PartnerIdService,
  ShareLinkService,
  TranslateWithPartnerIdParser,
} from '@theia-cc/shared/services';
import { BaseEffects, SharedStoreModule, WizardAction } from '@theia-cc/shared/store';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxLoadingModule } from 'ngx-loading';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { CONFIG_PARTNER_KEYS, WIZARD_CONFIG_PV } from './configs';
import { CONSTANTS_PV } from './configs/constants';
import { OfferPreviewSelectAreaComponentModalComponent } from './core/offer-preview-select-area-modal.component';
import { ParamsToCollectedDataResolverPv } from './core/params-to-collected-data.resolver';
import { ShareLinkServicePv } from './core/share-link.service';
import { StoreModule } from './store/store.module';
import { ContactModalModule } from '@theia-cc/pv/steps/contact-modal';

const LOCALE_CONFIG_PV: ILocaleConfig = {
  LOCALES: ['de', 'fr', 'it'],
  LOCALE_STRINGS_FORMAL: {
    de: 'assets/locales/de-formal.json',
    fr: 'assets/locales/fr-formal.json',
    it: 'assets/locales/it-formal.json',
  },
  LOCALE_STRINGS_INFORMAL: {
    de: 'assets/locales/de-informal.json',
    fr: 'assets/locales/fr-informal.json',
    it: 'assets/locales/it-informal.json',
  },
};

if (!environment.production) {
  console.log(JSON.stringify(environment));
}

@NgModule({
  imports: [
    AlertModule.forRoot(),
    BrowserModule,
    RouterModule.forRoot(
      [
        {
          path: 'bookmarklet',
          loadChildren: () => import('@theia-cc/shared/bookmarklet').then(m => m.BookmarkModule),
        },
        {
          path: 'digital-upload',
          loadChildren: () => import('@theia-cc/digital-upload').then(m => m.DigitalUploadModule),
          data: { trade: 'pv' },
        },
        {
          path: '',
          loadChildren: () => import('./steps/steps.module').then(m => m.StepsModule),
          resolve: { collectedData: ParamsToCollectedDataResolverPv },
          canActivate: [EntryStepGuard],
        },
      ],
      { scrollPositionRestoration: 'top' }
    ),
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      parser: {
        provide: TranslateParser,
        useClass: TranslateWithPartnerIdParser,
        deps: [PartnerIdService],
      },
    }),
    DeviceDetectorModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    SharedRootStepUiModule,
    SharedStoreModule,
    StoreModule,
    ModalModule.forRoot(),
    ScullyLibModule,
    ContactModalModule,
  ],
  declarations: [AppComponent, OfferPreviewSelectAreaComponentModalComponent],
  bootstrap: [AppComponent],
  providers: [
    getAppInitConfig(2),
    {
      provide: APP_INITIALIZER,
      useFactory: initAppWizardSettings,
      multi: true,
      deps: [WizardAction],
    },
    { provide: ShareLinkService, useClass: ShareLinkServicePv },
    { provide: LOCALE_CONFIG, useValue: LOCALE_CONFIG_PV },
    { provide: WIZARD_CONFIG, useValue: WIZARD_CONFIG_PV },
    { provide: ENVIRONMENT, useValue: environment },
    { provide: BASE_PATH, useValue: environment.apiEndpoint },
    { provide: CONSTANTS, useValue: CONSTANTS_PV },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: PARTNER_CONFIG_PV,
      useValue: <IPartnerConfigPv>{
        CONFIG_PARTNER_KEYS,
      },
    },
    CookieService,
    PvStateEffect,
    { provide: BaseEffects, useExisting: PvStateEffect },
    PvStateAction,
    ParamsToCollectedDataResolverPv,
  ],
})
export class AppModule {}
