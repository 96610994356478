<form #form="ngForm" class="contact-condensed py-3 px-4 px-md-5">
  <button
    [disabled]="loading"
    type="button"
    class="btn-close close pull-right"
    aria-label="Close"
    (click)="markAllAsTouched()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
  <h1>{{ 'CONTACT_EMAIL.TITLE' | translate }}</h1>
  <p>
    {{ (isPvx ? 'PV.CONTACT_INFORMATION.SUBTITLE' : 'CONTACT_CONDENSED.SUBTITLE') | translate }}
  </p>
  <app-radio-button
    [inputDisabled]="loading"
    [isRequired]="true"
    [options]="titleOptions"
    [inline]="true"
    [selectedOption]="title"
    [label]="'CONTACT_FORM.PERSON_TITLE' | translate"
    name="title"
    (outputValue)="updateProperty('title', $event)"></app-radio-button>
  <app-input
    [inputDisabled]="loading"
    [inputName]="'company-name'"
    [inputLabel]="'CONTACT_FORM.COMPANY' | translate"
    [inputValue]="companyName"
    (inputValueChange)="updateProperty('companyName', $event)"
    [maxLength]="100"
    *ngIf="isPvx">
  </app-input>
  <div class="row">
    <app-input
      [inputDisabled]="loading"
      class="col-md-6"
      [inputName]="'given-name'"
      [inputLabel]="'CONTACT_FORM.FIRST_NAME' | translate"
      [inputValue]="firstName"
      [maxLength]="30"
      (inputValueChange)="updateProperty('firstName', $event)">
    </app-input>
    <app-input
      [inputDisabled]="loading"
      class="col-md-6"
      [inputName]="'family-name'"
      [inputLabel]="'CONTACT_FORM.LAST_NAME' | translate"
      [inputValue]="lastName"
      [maxLength]="30"
      (inputValueChange)="updateProperty('lastName', $event)">
    </app-input>
  </div>
  <div>
    <app-input
      [inputDisabled]="loading"
      *ngIf="showStreet"
      [inputName]="'street'"
      [inputLabel]="'CONTACT_FORM.STREET' | translate"
      [inputValue]="street"
      [maxLength]="50"
      (inputValueChange)="updateProperty('street', $event)">
    </app-input>
    <div class="row" *ngIf="showZip || showPlace">
      <app-input
        [inputDisabled]="loading"
        *ngIf="showPlace"
        class="col-md-6"
        [inputName]="'city'"
        [inputLabel]="'CONTACT_FORM.PLACE' | translate"
        [inputValue]="place"
        [maxLength]="30"
        (inputValueChange)="updateProperty('place', $event)">
      </app-input>
      <app-input
        [inputDisabled]="loading"
        *ngIf="showZip"
        class="col-md-6"
        [inputType]="'number'"
        [inputName]="'zip'"
        [inputLabel]="'CONTACT_FORM.ZIP' | translate"
        [inputValue]="zip"
        [maxLength]="15"
        [inputPattern]="'^[0-9]*$'"
        (inputValueChange)="updateProperty('zip', $event)">
      </app-input>
    </div>

    <app-input
      [inputDisabled]="loading"
      [inputType]="'email'"
      [inputName]="'email'"
      [inputLabel]="'CONTACT_FORM.EMAIL' | translate"
      [validationError]="validationError"
      [inputValue]="email"
      [maxLength]="100"
      (inputValueChange)="updateProperty('email', $event)">
    </app-input>
    <app-input
      [inputDisabled]="loading"
      [inputType]="'tel'"
      [inputName]="'tel'"
      [inputLabel]="'CONTACT_FORM.PHONE' | translate"
      [inputValue]="telephone"
      [maxLength]="50"
      (inputValueChange)="updateProperty('telephone', $event)">
    </app-input>

    <div class="d-flex mb-4 w-100">
      <div>{{ 'CONTACT_EMAIL.PRIVACY' | translate }}</div>
      <div class="ml-5 ml-md-2" [tooltip]="privacyTooltipTemplate" placement="top" container="body">
        <img src="assets/icons/var/icn-info.svg" />
      </div>
      <ng-template #privacyTooltipTemplate>
        <div
          class="contact-modal-privacy-tooltip"
          [innerHtml]="'PV.ADDRESS_LOOKUP.TOOLTIP' | translate"></div>
      </ng-template>
    </div>

    <app-checkbox
      [disabled]="loading"
      class="mb-4"
      [required]="true"
      [checked]="emailDisclaimer"
      (change)="toggleAgreement()"
      [id]="'emailDisclaimer'">
      <span [innerHtml]="'PARTNER.PRIVACY_USER_AGREEMENT' | translate"></span>
    </app-checkbox>

    <app-textarea
      [inputDisabled]="loading"
      inputName="comment"
      [inputLabel]="'CONTACT_FORM.COMMENT' | translate"
      [inputValue]="comment"
      (outputValue)="updateProperty('comment', $event)">
    </app-textarea>
  </div>

  <div class="d-flex justify-content-between">
    <app-button
      [facet]="'link'"
      (buttonClicked)="sharedEffect.navigateBack()"
      [attr.data-test]="'close'"
      [disabled]="loading">
      {{ 'PV.OFFERPREVIEW.BUTTON_BACK' | translate }}
    </app-button>
    <app-button
      [shadow]="true"
      [attr.data-test]="'submit'"
      (buttonClicked)="submit()"
      [disabled]="loading"
      [loading]="loading">
      {{ 'PV.OFFERPREVIEW.VIEW_OFFER' | translate }}
    </app-button>
  </div>
</form>
