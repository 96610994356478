import { AfterViewInit, Directive, ElementRef, OnDestroy, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: 'input[maxlength]',
})
export class InputNumberMaxLengthDirective implements AfterViewInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  constructor(private elementRef: ElementRef, @Optional() private control: NgControl) {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    if (
      this.element &&
      this.element.type === 'number' &&
      Number.isFinite(+this.maxLength) &&
      +this.maxLength > 0
    ) {
      this.subscription.add(
        fromEvent(this.element, 'input')
          .pipe()
          .subscribe(() => this.updateValue())
      );
    }
  }

  updateValue() {
    if (this.element.value && this.element.value.length > this.maxLength) {
      const value = this.element.value.slice(0, this.maxLength);
      this.element.value = value;
      if (this.control) {
        this.control.control.setValue(value);
      }
    }
  }

  get maxLength(): number {
    return this.element.maxLength;
  }

  get element(): HTMLInputElement {
    return this.elementRef.nativeElement;
  }
}
