import { NboViewModel } from '@api-cc';
import { IAppStatePv } from '@theia-cc/pv/state';

export const INITIAL_STATE: IAppStatePv = {
  config: {
    envVariables: {
      appKey: null,
      localeStyle: null,
      enablePhoneHeader: true,
      disableLanguageChoice: false,
    },
    queryParams: {
      partnerId: null,
      origin: null,
      mode: null,
    },
    org: {
      name: null,
      contactPhone: null,
      contactEmail: null,
      logoImageUrl: null,
      logoLinkUrl: {
        de: null,
        fr: null,
        it: null,
      },
      contactOfferImageUrls: null,
      stylesheetUrl: null,
      faviconUrl: null,
      successPageUrls: {
        de: null,
        fr: null,
        it: null,
      },
    },
    styles: null,
    defaultLanguage: null,
    steps: [null],
    configLoaded: false,
    versionNumber: null,
    hubspotTrackingId: null,
    offerPreview: null,
    highDrainSystemItems: [],
    templatesSloped: null,
    templatesFlat: null,
  },
  currentLanguage: null,
  wizard: {
    isSharedOfferPreview: undefined,
    showLoadingSpinner: false,
    showOfferPreviewLoadingSpinner: false,
    allowNextStep: false,
    currentStep: null,
    previousStep: null,
    currentStepIdx: null,
    lastStep: null,
    totalSteps: null,
    currentStepData: null,
    navbar: {
      primaryButton: {
        name: null,
        action: null,
        facet: null,
        disabled: null,
        hidden: null,
      },
      secondaryButton: {
        name: null,
        action: null,
        facet: null,
        disabled: null,
        hidden: null,
      },
      showShareButton: true,
    },
  },
  device: {
    device: null,
    browser: null,
  },
  collectedData: {
    nboFetchedPv: [],
    userAddress: {
      egid: null,
      zip: null,
      place: null,
      street: null,
      lat: null,
      lon: null,
      x: null,
      y: null,
      addressString: null,
      hasGeometry: null,
    },
    user: {
      companyName: null,
      email: null,
      emailDisclaimer: null,
      firstName: null,
      lastName: null,
      telephone: null,
      title: null,
    },
    apartmentCount: null,
    averagePowerUsageKwh: null,
    buildingType: null,
    familyMemberCount: null,
    highDrainSystems: {
      HEATPUMP: null,
      ELECTRIC_HEATING: null,
      ELECTROBOILER: null,
      EMOBILITY_CHARGING: null,
      POOL_SAUNA: null,
    },
    battery: null,
    electroBoiler: null,
    heatPump: null,
    chargingStationEmobility: null,
    heatPumpBoiler: null,
    highDrainSystemsKwh: null,
    isPvx: null,
    nboLeadIdPv: null,
    internalLeadIdPv: null,
    previewImage: null,
    roofArea: null,
    maxPvCoveredRoofArea: null,
    roofAreaChf: null,
    roofAreaKwh: null,
    roofAreaLifetimeChf: null,
    roofMaterial: null,
    roofShadow: null,
    roofTiltAngle: null,
    selectedRoofArea: null,
    totalPowerRequirementKwh: null,
    nboSelectedTemplateIdPv: null,
    leadComment: null,
    leadCallbackTime: {
      IsCallbackTimeMorning: null,
      IsCallbackTimeAfternoon: null,
      IsCallbackTimeEvening: null,
      IsCallbackTimeWeekend: null,
    },
    leadStatus: null,
    leadTag: null,
    leadTracers: null,
    buildingData: {
      aboveSea: null,
      buildingEavesHeight: null,
      buildingGroundArea: null,
      buildingLevels: null,
      buildingRidgeHeight: null,
      buildingRoofArea: null,
      buildingRoofOverhangArea: null,
      buildingRoofShape: null,
      buildingVolume: null,
      buildingWallArea: null,
    },
    selectedTemplatePv: null,
    pvEarningsKwhM2: null,
    roofOrientation: null,
    showAddressFields3dFailsPv: false,
  },
};

const { collectedData, ...rest } = INITIAL_STATE;
export const INITIAL_STATE_CORE: StateCore = rest;

export type StateCore = Omit<IAppStatePv, 'collectedData'>;
