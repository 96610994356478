<div
  class="radio-buttons form-group"
  [class.required]="isRequired"
  [class.invalid]="isRequired && !inputDisabled && !selectedOption">
  <label>{{ label | translate }}</label>
  <form
    #radioForm
    [ngClass]="{
      'radio-buttons__options': true,
      'radio-buttons__options--inline': inline
    }">
    <div class="custom-control custom-radio" *ngFor="let option of options">
      <input
        #inputRef
        #input="ngModel"
        type="radio"
        class="custom-control-input"
        [required]="isRequired"
        [disabled]="inputDisabled"
        [attr.id]="'option-' + randomId + '-' + option.id"
        [value]="option.value"
        [attr.name]="name"
        [(ngModel)]="selectedOption"
        (ngModelChange)="updateModel($event)" />
      <label
        class="custom-control-label"
        [attr.data-test]="option.value"
        [attr.for]="'option-' + randomId + '-' + option.id"
        [innerHTML]="option.label | translate"></label>
    </div>
  </form>
</div>
