<div
  class="custom-control custom-checkbox"
  [class.required]="required"
  [class.invalid]="required && !disabled && !checked">
  <input
    #inputRef
    type="checkbox"
    class="custom-control-input"
    [required]="required"
    [disabled]="disabled"
    [attr.id]="id"
    [checked]="checked"
    (change)="checkboxChange.emit($event)" />
  <label class="custom-control-label" [attr.for]="id">
    <span>
      <ng-content></ng-content>
    </span>
  </label>
</div>
