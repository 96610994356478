import { Component, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { IAppStatePv, PvStateEffect } from '@theia-cc/pv/state';
import { Observable } from 'rxjs';
import {
  NboAddressViewModel,
  PatchNboRequest,
  PvVariantSummaryViewModel,
  PvVariantSummaryViewModelLeadSummaryViewModel,
} from '@api-cc';
import { googleTagManagerPush, IOption, TITLE_OPTIONS, validators } from '@theia-cc/shared/helpers';
import {
  LeadMetaAction,
  SharedStoreEffect,
  UserInfoAction,
  WizardAction,
} from '@theia-cc/shared/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CheckboxComponent, InputComponent, RadioButtonComponent } from '@theia-cc/shared/form-ui';
import { APP_NAME } from '../../../../../../../apps/pv/src/app/configs';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ContactModalComponent implements OnInit {
  @select((store: IAppStatePv) => store.config.org.contactOfferImageUrls[store.currentLanguage])
  contactOfferImageUrl$: Observable<string>;
  @select((store: IAppStatePv) => !store.collectedData?.nboLeadIdPv)
  isShowEmail$: Observable<boolean>;

  @ViewChildren(RadioButtonComponent) radioButtons: QueryList<RadioButtonComponent>;
  @ViewChildren(InputComponent) inputFields: QueryList<InputComponent>;
  @ViewChildren(CheckboxComponent) checkboxFields: QueryList<CheckboxComponent>;

  public companyName: string;
  public firstName: string;
  public lastName: string;
  public telephone: string;
  public email: string;
  public emailDisclaimer: boolean;
  public zip = '';
  public street = '';
  public place = '';
  public comment = '';
  public showZip: boolean;
  public showStreet: boolean;
  public showPlace: boolean;
  public title: NboAddressViewModel.TitleEnum;
  public validationError = false;
  public titleOptions: IOption[] = [];

  public isPvx: boolean;
  public loading = false;

  constructor(
    private effect: PvStateEffect,
    private wizardAction: WizardAction,
    private userInfoAction: UserInfoAction,
    private leadMetaAction: LeadMetaAction,
    private store: NgRedux<IAppStatePv>,
    public modalRef: BsModalRef,
    public sharedEffect: SharedStoreEffect
  ) {}

  ngOnInit() {
    const appState: IAppStatePv = this.store.getState();
    this.isPvx = appState.collectedData.isPvx;
    this.titleOptions = TITLE_OPTIONS.filter(({ value }) =>
      this.isPvx
        ? value !== NboAddressViewModel.TitleEnum.Family
        : value !== NboAddressViewModel.TitleEnum.Company
    );
    const { firstName, lastName, telephone, title, email, emailDisclaimer } =
      appState.collectedData.user;
    this.firstName = firstName;
    this.lastName = lastName;
    this.title = title;
    this.telephone = telephone;
    this.email = email;
    this.emailDisclaimer = emailDisclaimer;

    const { userAddress } = appState.collectedData;
    this.zip = userAddress.zip;
    this.street = userAddress.street;
    this.place = userAddress.place;

    this.showZip = !this.zip || !userAddress.hasGeometry;
    this.showStreet = !this.street || !userAddress.hasGeometry;
    this.showPlace = !this.place || !userAddress.hasGeometry;

    this.comment = appState.collectedData.leadComment;
  }

  isValid() {
    return (
      this.email &&
      validators.email(this.email) &&
      this.emailDisclaimer &&
      this.firstName &&
      this.lastName &&
      this.telephone &&
      this.title &&
      this.zip &&
      this.place &&
      this.street
    );
  }

  updateProperty(prop, value) {
    this[prop] = value;
    this.updateCurrentStepData();
  }

  updateCurrentStepData() {
    this.isValid() ? this.wizardAction.allowNextStep() : this.wizardAction.disallowNextStep();
    this.validationError = !validators.email(this.email);
    this.userInfoAction.storeUser({
      firstName: this.firstName,
      lastName: this.lastName,
      title: this.title,
      companyName: this.companyName,
      telephone: this.telephone,
      email: this.email,
      emailDisclaimer: this.emailDisclaimer,
    });

    this.userInfoAction.storeUserAddress({
      zip: this.zip,
      place: this.place,
      street: this.street,
    });

    this.leadMetaAction.storeLeadComment(this.comment);
  }

  toggleAgreement() {
    this.emailDisclaimer = !this.emailDisclaimer;
    this.updateCurrentStepData();
  }

  markAllAsTouched() {
    [...this.inputFields, ...this.checkboxFields, ...this.radioButtons].forEach(input => {
      input?.reportValidity();
    });
  }

  submit() {
    this.markAllAsTouched();
    if (this.isValid()) {
      this.loading = true;
      this.leadMetaAction.storeLeadStatus(PatchNboRequest.LeadStatusEnum.OfferDownload);
      this.leadMetaAction.storeLeadTracers(PatchNboRequest.LeadStatusEnum.Callback);

      googleTagManagerPush({
        event: 'GAEvent',
        eventCategory: 'Conversion',
        eventAction: 'CRM Email',
        eventLabel: APP_NAME,
        eventValue: undefined,
      });
      googleTagManagerPush({
        event: 'GAEvent',
        eventCategory: 'Conversion',
        eventAction: 'CRM Address',
        eventLabel: APP_NAME,
        eventValue: undefined,
      });

      const appState: IAppStatePv = this.store.getState();
      const { nboLeadIdPv } = appState.collectedData;
      const leadCreationRequest: Promise<void | PvVariantSummaryViewModel[]> = nboLeadIdPv
        ? Promise.resolve()
        : this.effect
            .createPvNboLead(PatchNboRequest.LeadStatusEnum.OfferDownload)
            .then((data: PvVariantSummaryViewModelLeadSummaryViewModel) => {
              this.effect.handleGoogleTagsAfterInternalLeadIdIsPresent(
                data.InternalLeadId.toString(),
                data.LeadId
              );

              return this.effect.addVariantToLead({ isFinalVariant: false });
            });
      leadCreationRequest.then(() => {
        this.modalRef.hide();
        this.wizardAction.hideLoadingSpinner();
      });
    }
  }
}
